import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Article from './Article';
import './ArticleList.css';

const baseURL = "https://warehouse-picker-proxy.qustom.workers.dev/proxy";

const ArticleList = ({ conneoId, batchId }) => {
  const [articles, setArticles] = useState([]);
  const [pickedArticles, setPickedArticles] = useState(() => {
    return JSON.parse(localStorage.getItem('pickedArticles')) || {};
  });
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false); // New state to track if processing is in progress
  const navigate = useNavigate();

  useEffect(() => {
    if (conneoId && batchId) {
      const URL = `${baseURL}/${conneoId}/${batchId}`;

      fetch(URL)
        .then(response => {
          if (!response.ok) {
            if(response.status === 403) throw new Error(`Forbidden access! please provide a valid id`);
            throw new Error(`Network response was not ok: ${response.status}`);
          }
          const contentType = response.headers.get('content-type');
          if (contentType && contentType.indexOf('application/json') !== -1) {
            return response.json();
          } else {
            return response.text().then(text => {
              throw new Error(`Expected JSON, got: ${text}`);
            });
          }
        })
        .then(data => {
          if (data.meta.status === "error") {
            setArticles([]); // Clear articles if there is an error
          } else {
            setArticles(data.data.items);
          }
        })
        .catch(error => {
          setError(error.message);
        });
    }
  }, [conneoId, batchId]);

  const handleProcess = async () => {
    setIsProcessing(true); // Disable the button when the process starts

    const items = Object.entries(pickedArticles).map(([id, { mainQuantity, locations }]) => {
      const article = articles.find(article => article.id === parseInt(id));
      if (!article) {
        console.error(`Article with id ${id} not found`);
        return null; // Skip this item if the article is not found
      }

      return {
        orderNumber: article.orderNumber,
        id: parseInt(id),
        job: article.job,
        locations: [
          {
            pickQuantity: mainQuantity,
            locationId: article.locationId
          },
          ...Object.entries(locations || {}).map(([locationId, pickQuantity]) => ({
            pickQuantity,
            locationId: parseInt(locationId)
          }))
        ].filter(location => location.pickQuantity > 0)
      };
    }).filter(item => item !== null); // Remove any null items

    if (items.length === 0) {
      setError('No quantities picked to process.');
      setIsProcessing(false); // Re-enable the button if no items to process
      return;
    }

    const payload = {
      items,
      picklist: {
        key: batchId,
        code: ""
      }
    };

    try {
      const response = await fetch(`${baseURL}/${conneoId}/${batchId}`, {

        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      console.log(JSON.stringify(payload))
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      if (data.data.status === 'ok') {
        alert('Data processed successfully!');
        localStorage.removeItem('pickedArticles');
        setPickedArticles({});
        window.location.reload();
       // navigate(`/${conneoId}/${batchId}`); // Redirect to /conneoId after successful processing
      } else {
        alert(data.data);
        setIsProcessing(false); // Re-enable the button if processing fails
      }
    } catch (error) {
      setError('Failed to process the picked quantities. Please try again.');
      setIsProcessing(false); // Re-enable the button if an error occurs
    }
  };

  return (
    <div className="article-list">
      {error && (
        <>
          <p className="error-message">{error}</p>
          <button onClick={() => navigate(`/${conneoId}`)} className="btn-back">
            ← Terug
          </button>
        </>
      )}

      {articles.length === 0 && !error && (
        <>
          <button onClick={() => navigate(`/${conneoId}`)} className="btn-back">
            ← Terug
          </button>
        </>
      )}

      {articles.map(article => (
        <Article
          key={article.id}
          article={article}
          pickedArticles={pickedArticles}
          setPickedArticles={setPickedArticles}
        />
      ))}

      {articles.length > 0 && (
        <div className="button-group-single">
          <button 
            onClick={handleProcess} 
            className="btn-process" 
            disabled={isProcessing} // Disable button when isProcessing is true
          >
            {isProcessing ? 'Processing...' : 'Process All'}
          </button>
        </div>
      )}
    </div>
  );
};

export default ArticleList;
